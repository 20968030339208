body, html {
  width: 100%;
  height: 100%;
  background-color: var(--chakra-colors-quredGray-600);
}

:root {
  --header-height: 48px;
  --sidebar-width: 236px;
  --footer-height: 64px;
}

/* Caution - If this is removed or altered it will cause chainging visibility of Goog. Autocomplete Widget */
/* Change the z-index of the pac-container to be above chakra-ui modals */
.pac-container {
  z-index: calc(var(--chakra-zIndices-modal) + 100);
}

/* hide powered by google logo */
.pac-logo::after {
  display: none !important;
  background-image: none !important;
}
